





































import { Component, Vue } from 'vue-property-decorator';
import firebase from 'firebase/app';
import 'firebase/auth';
import router from '@/router';

@Component({
  components: {
  },
})
export default class Register extends Vue {
    public FormState = true;
    public ModalOpen = false;

    public MailAddress = '';
    public Password = '';
    public PasswordCheck = '';

    public EmailAddressErrorMessage = '';
    public PasswordErrorMessage = '';
    public PasswordCheckErrorMessage = '';

    Send(): void {
        let state = true;
        this.EmailAddressErrorMessage = '';
        this.PasswordErrorMessage = '';
        this.PasswordCheckErrorMessage = '';
        
        var mailAddressRegex = /^[A-Za-z0-9]{1}[+A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/;

        if(this.MailAddress == '') {
            this.EmailAddressErrorMessage = 'メールアドレスを入力してください';
            state = false;
        }

        if(mailAddressRegex.test(this.MailAddress) == false) {
            this.EmailAddressErrorMessage = '正しいメールアドレスを入力してください';
            state = false;
        }

        if(this.Password == '') {
            this.PasswordErrorMessage = 'パスワードを入力してください';
            state = false;
        }

        if(this.Password.length <= 6) {
            this.PasswordErrorMessage = '6文字以上でパスワードを入力してください';
            state = false;
        }

        if(this.Password != this.PasswordCheck) {
            this.PasswordCheckErrorMessage = 'パスワードが一致しません';
            state = false;
        }

        if(state == true) {
            firebase.auth().createUserWithEmailAndPassword(this.MailAddress, this.Password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                this.FormState = false;
                this.ModalOpen = true;
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorCode);
                console.error(errorMessage);

                if(errorCode == 'auth/email-already-in-use') {
                    this.EmailAddressErrorMessage = '既に登録されています';
                }
            });
        }
    }

    Ok(): void {
        router.push({name: 'Home'});
    }
}
